import React from "react"
import * as styles from "./portfolio.module.scss"
import Image from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const ProjectDescription = ({ title, services, description, image }) => {
  const intl = useIntl()
  return (
    <div className={styles.pdContent}>
      <Image
        fluid={image}
        className={styles.pdImage}
        alt="Project Description"
      />
      <div className={styles.pdTextContainer}>
        <h1 className={[styles.fontDinproBold, styles.whiteText].join(" ")}>
          {" "}
          {title}{" "}
        </h1>
        <div
          className={[
            styles.fontDinproBold,
            styles.blackText,
            styles.margin,
            styles.smallerText,
          ].join(" ")}
        >
          {" "}
          {intl.formatMessage({ id: "portfolio.services" })}
          <span
            className={[
              styles.fontDinproBold,
              styles.grayText,
              styles.margin,
              styles.smallerText,
            ].join(" ")}
          >
            {" "}
            {services}{" "}
          </span>
        </div>
        <div
          className={[
            styles.fontDinproMedium,
            styles.descriptionTitleText,
            styles.margin,
            styles.smallerText,
          ].join(" ")}
        >
          {intl.formatMessage({ id: "portfolio.description" })}
        </div>
        <div style={{ width: "100%" }}>
          <div
            className={[
              styles.wrap,
              styles.relative,
              styles.fontLatoMedium,
              styles.darkGreyText,
              styles.margin__smaller,
            ].join(" ")}
          >
            {description}
            <div className={styles.descriptionBar} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDescription
