import React, { useState } from "react"
import * as styles from "./portfolio.module.scss"
import { graphql } from "gatsby"
import ImageGrid from "./ImageGrid"
import ProjectDescription from "./ProjectDescription"
import Footer from "../../Homepage/Footer/Footer"
import SidebarMenu from "../../Sidebar/SidebarMenu"
import Header from "../../Header/Header"
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"

const PortfolioClicked = ({ data, pageContext }) => {
  const getPortfolio = portfolioDataArray => {
    const imageArray = portfolioDataArray.portfolio.edges
      .map(node => {
        if (node.node.childImageSharp !== null) {
          return node.node.childImageSharp.fluid
        }
        return null
      })
      .filter(v => v !== null)

    return {
      imageArray: imageArray,
      folderName: pageContext.folderName.toLowerCase().replace(/ /g, "-"),
      headerImage: portfolioDataArray.header.childImageSharp.fluid,
    }
  }

  const [state] = useState(() => {
    return getPortfolio(data)
  })
  const intl = useIntl()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Competdac - " +
            intl.formatMessage({
              id: "portfolio." + state.folderName + ".title",
            })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({
            id: "portfolio." + state.folderName + ".description",
          })}
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <SidebarMenu name="PORTFOLIO" />
        <div className={styles.relative}>
          <Header image={state.headerImage} pageName="PORTFOLIO" />
          <div className={styles.content}>
            <ProjectDescription
              title={intl.formatMessage({
                id: "portfolio." + state.folderName + ".title",
              })}
              services={intl.formatMessage({
                id: "portfolio." + state.folderName + ".service",
              })}
              description={intl.formatMessage({
                id: "portfolio." + state.folderName + ".description",
              })}
              image={state.imageArray[state.imageArray.length - 1]}
            />
            <ImageGrid images={state.imageArray} />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioClicked

export const query = graphql`
  query MdPhoto($folderName: String!) {
    portfolio: allFile(
      filter: {
        relativeDirectory: { eq: $folderName }
        extension: { eq: "webp" }
        sourceInstanceName: { eq: "portfolio" }
      }
      sort: { order: ASC, fields: childImageSharp___original___height }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(fit: COVER, webpQuality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    header: file(relativePath: { eq: "Hero/hero_portfolio.webp" }) {
      childImageSharp {
        fluid(maxWidth: 9500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
